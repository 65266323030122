$font-family: "Inter", sans-serif;
$title-font: "Inter", sans-serif;


$blue: #3e53a6;
$light-blue: #5089a6;
$dark-gray: #5c5d63;
$dark-green: #56762D;
$breadcrumb-grey: #535454;
$gray: #66676d;
$light-gray: #dadada;
$button-grey: #8e8e8e;
$separator-grey: #ececec;
$red: #ba5555;
$white: #ffffff;
$menu-link-color: #325A34;
$submenu-link-color: #878d92;
$input-bg: #eceff4;
$theme-green: #78B843;
$lang-selected: #81ba36;
$lang-bg: #ebebeb;
$lang-text-color: #9f9f9f;
$header-bg: #ffffff;
$map-blue: #3eb6ef;
$body-text-color: #5B5B5B;
$dark-text-color: #25292b;
$country-nav-color: #2b4767;
$nav-border-color: #f1f1f1;
$nav-tab-border-color: #eeeeee;
$footer-bg: #325A34;
$footer-separator: #3B6B3C;
$menu-bg: #D9D6CA;

$blue: #3e53a6;
$green: #78B843;
$afo-green: #78B843;
$afo-hover: #446f21;

$health-color: #5baf95;
$light-green: #31ba1a;
$partner-hover-bg: rgba(120,184,67, 0.9);

$extend: 100%;
$base-font-size: 16px;
$charts-options-height: 66px;
$charts-options-font-size: 1.02rem;

$base-unit: 10px;

@mixin gray-raleway-font {
  font-size: $charts-options-font-size;
  color: $gray;
  font-weight: 500;
  font-family: "Raleway";
}
@mixin border-radius($radius) {
	border-radius: $radius;
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
}
@mixin border-radii($topleft, $topright, $bottomright, $bottomleft) {
	border-top-left-radius: $topleft;
	border-top-right-radius: $topright;
	border-bottom-right-radius: $bottomright;
	border-bottom-left-radius: $bottomleft;
	-webkit-border-top-left-radius: $topleft;
	-webkit-border-top-right-radius: $topright;
	-webkit-border-bottom-right-radius: $bottomright;
	-webkit-border-bottom-left-radius: $bottomleft;
	-moz-border-radius-topleft: $topleft;
	-moz-border-radius-topright: $topright;
	-moz-border-radius-bottomright: $bottomright;
	-moz-border-radius-bottomleft: $bottomleft;
}


// Mobile screen sizes
// Define the breakpoint values
$breakpoint-desktop: 1025px;
$breakpoint-tablet: 834px;
$breakpoint-phone: 480px;

// Mixin for desktop screens
@mixin desktop {
	@media (min-width: $breakpoint-desktop) {
	  @content;
	}
  }

// Mixin for tablet screens
@mixin tablet {
  @media (max-width: $breakpoint-tablet) {
    @content;
  }
}

// Mixin for phone screens
@mixin phone {
  @media (max-width: $breakpoint-phone) {
    @content;
  }
}
