
.container {
  &.desktop {
    h2 {
      .title {
        padding-top: $base-unit + 40;
      }
    }
  }
}

.container.green {
  //border: 1px dotted red;
  //width: 1500px;
  //  margin-top: $base-unit + 10;

  .wp-block-column {
    //margin: 0.2em;
    //border: 1px solid green;
    figure,
    p {
      //  border: 1px solid green;
      margin-block-end: 0;
      margin-block-start: 0em;
    }
  }

  .excerpt {
    .entry-content {
      margin: 0;
    }
  }
}

.container.section {
  margin-left: 0px;
  margin-bottom: 50px;

  .ui.menu.header {
    margin: 0;
    background-color: #FFF;
    border: 1px solid $light-gray;
    background: $health-color;

    h1 {
      font-family: 'Roboto', sans-serif;
      font-size: 2rem;
      font-weight: 300 !important;
      padding: 0;
      margin: 0;
    }

    h2 {
      font-family: 'Roboto', sans-serif;
      font-size: 1.5rem !important;
      margin: 0;
      padding: 0;
      font-weight: 300 !important;
    }

    .item {
      padding: 1.5rem;
      background: $health-color;
      width: 20vw;
      max-height: 160px !important;
      max-width: 191px !important;
    }

    .ui.image {
      margin: auto;
      width: 60%;
    }

    .header {
      padding: 2%;
      background: #FFF;
      width: 100%;
      font-weight: 300;
      justify-content: left;
      align-items: center;
      display: flex;

      > div {
        margin-left: 1.5vw;
      }

      p {
        line-height: 100%;
        padding: 0;
        margin: 0;
      }

      border-left: 1px solid $light-gray;
    }
  }

  .entry-content {


  }

  @media (max-width: 600px) {
    .ui.menu {
      .item {
        display: none;
      }
    }
  }

  .subsection {
    margin: 0 !important;

    a {
      color: #3e9fde !important;
    }

    //background-color: #f4f4f4;
    padding: 1vw;

    .grid.header {

      h2.title {
        text-transform: none;
        padding-left: 0;
        color: $health-color;
        font-weight: 300;
        margin: 0;
      }

      h3.subtitle {
        text-transform: none;
        font-size: 1.5rem;
        margin: 0;
        color: $gray;
        font-weight: 300;
        padding-left: 0;
      }

      padding: 0.5vw;
      margin: 0;

      .column,
      .row {
        padding: 0;
        margin: 0;
      }

      .column {
        min-height: $base-unit * 9;
        border-bottom: 1px solid $light-gray;
      }

      .icon {
        width: 40px;
        height: 40px;
        min-width: 30px !important;
        min-height: 30px !important;
        //background: url('/sub_section_icon.svg') no-repeat center;
        content: '';
        margin-top: $base-unit + 5;
      }

      @media (max-width: 779px) {
        .column:nth-child(1) {
          display: none;
        }

        h2.title {
          font-size: 1.5em;
        }

        h3.subtitle {
          font-size: 1.3em;
        }
      }
    }
  }
}

.intro {
  margin: $base-unit * 5 auto auto;
  padding: 0;

  .entry-content {
    padding: 0 !important;
    margin: 0;

    .has-intermediate-font-size {
      font-size: 1.8rem;
      line-height: 135%;
      padding-bottom: 35px;
      padding-top: 25px;
    }
  }

  @media screen and (max-width: 781px) and (min-width: 600px) {
    .wp-block-columns {
      margin-bottom: 10vh !important;
    }

    .wp-block-column:nth-child(1) {
      flex-basis: calc(20% - 5px) !important;
      flex-grow: 0;
    }

    .wp-block-column:nth-child(2) {
      flex-basis: calc(80% - 16px) !important;
      flex-grow: 0;
    }
  }
  @media (max-width: 599px) {
    .wp-block-column:nth-child(2) {
      text-align: center;
      flex-grow: 0;
    }
  }

  .wp-block-columns {
    margin: 0;

    .wp-block-column {
      p {
        margin: 0.3em;
      }

      figure {
        margin-block-end: 0;
        margin-block-start: 0.3em;
      }

      margin: 0;
      padding: 0.3vw;
    }
  }
}

.cost {
  .cost-gender-label {
    line-height: 90%;
  }
}
