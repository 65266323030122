.chart.container {
  text-align: right;
  margin: 0px;

  padding: 0px;

  .body {
    padding: 0px;
    margin: 0px;

    .ui.container.entry-content {
      padding: 0px !important;
      margin: 0px !important;;

    }

    svg {
      text {
        font-size: 13px !important;
      }
    }
  }

  .footnote {
    width: 100%;
    text-align: left;

    .grid {
      width: 100%;
    }

    .column {

      height: 40px;
      vertical-align: bottom;
      padding: 0px;
      margin: 0px;
    }

    padding: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: auto;
    margin-bottom: 0px;

    p {
      line-height: 40px;
      font-size: 13px;
      padding: 0px;
      margin-left: 10px;
      margin-right: 10px;
      display: flex;

    }

    .button {
      font-size: 17px;
      background-color: #D6D6D6 !important;;
      font-weight: 100;
      border: 1px solid;
      width: 157px !important;
      height: 40px !important;
      font-weight: 500;
      color: white !important;
      padding-left: 5px !important;
      padding-right: 2px !important;
      display: flex;
      align-items: center;
      justify-content: center;

    }

    .button.active {
      background: #4F8BA5 !important;
    }
  }

  .button.download {
    cursor: pointer;
    font-size: 14px;
    background-color: transparent !important;;
    font-weight: 100;
    padding: 0px !important;;
    color: #5aade4 !important;
    border: 1px solid #5aade4;
    width: auto !important;
    height: 30px !important;
    padding: 0px 7px 0px 7px !important;
    margin-bottom: 15px !important;

    .download.icon:before {
      //display: block;
      //content: "";
      // width: 20px;
      // height: 27px;
      //  background-image: url("/download.png") !important;
      margin-left: 10px !important;
      //  margin-top: -7px;
    }

  }

}
.chart.tooltip {
  border-radius: 4px;
  opacity: .9;
  padding: 7px;
  font-size: 16px;
  color: #FFF2F2;
}

// Chart filters
.react-datepicker__quarter-text{
  width: 2rem !important;
}
.react-datepicker__month .react-datepicker__month-text{
  font-size: 0.55rem;
  font-family: $font-family;
  padding-bottom: $base-unit - 2;
  padding-top: $base-unit - 2;
  width: 3rem !important;
}
.react-datepicker__header{
  padding-top: 5px !important;
}
.react-datepicker-year-header{
  font-size: 0.7rem !important;
  padding-bottom: math.div($base-unit, 2) !important;
}
.react-datepicker__navigation{
  top: 5px !important;
  transform: scale(0.7);
}

#home-map {
  height: 530px !important;
  width: 550px;
  @include phone{
    margin-left: -28%;
  }
  a{
    color: $header-bg;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    font-size: $base-font-size;
  }
  &.leaflet-container{
    background-color: transparent !important;
    margin-top: -80px;
  }
  .leaflet-popup-content-wrapper {
    @include border-radius(5px);
    background-color: $map-blue;
    color: $header-bg;
    font-weight: bold;
    min-height: unset;
    min-width: 250px;
    padding: 40px;
    text-align: left;
  }
  .home-map-popup{
    background-color: $map-blue;
    min-width: 150px;
    .link-label{
      display: block;
      font-size: 12px;
    }
    .link-icon{
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI2LjAuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIGZvY3VzYWJsZT0iZmFsc2UiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiCgkgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6I0ZGRkZGRjt9Cjwvc3R5bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0yNTYsNTA0YzEzNywwLDI0OC0xMTEsMjQ4LTI0OFMzOTMsOCwyNTYsOFM4LDExOSw4LDI1NlMxMTksNTA0LDI1Niw1MDR6IE00MCwyNTZjMC0xMTguNyw5Ni4xLTIxNiwyMTYtMjE2CgljMTE4LjcsMCwyMTYsOTYuMSwyMTYsMjE2YzAsMTE4LjctOTYuMSwyMTYtMjE2LDIxNkMxMzcuMyw0NzIsNDAsMzc1LjksNDAsMjU2eiBNMzcxLjcsMjM4bC0xNzYtMTA3Yy0xNS44LTguOC0zNS43LDIuNS0zNS43LDIxCgl2MjA4YzAsMTguNCwxOS44LDI5LjgsMzUuNywyMWwxNzYtMTAxQzM4OC4xLDI3MC45LDM4OC4xLDI0Ny4yLDM3MS43LDIzOHogTTE5MiwzMzUuOFYxNzYuOWMwLTQuNyw1LjEtNy42LDkuMS01LjFsMTM0LjUsODEuNwoJYzMuOSwyLjQsMy44LDguMS0wLjEsMTAuM0wyMDEsMzQxQzE5NywzNDMuMywxOTIsMzQwLjQsMTkyLDMzNS44eiIvPgo8L3N2Zz4K");
      background-repeat: no-repeat;
      background-size: 30px 30px;
      display: inline-block;
      float: left;
      height: 30px;
      margin-bottom: 40px;
      margin-right: 10px;
      margin-top: math.div($base-unit, 2) .5;
      width: 30px;
    }
  }
  .leaflet-popup-tip{
    background-color: $map-blue;
  }
}

.chart-container{
  svg{
    text[transform="translate(0,9) rotate(-40)"]{
      @include phone{
        font-size: 5px !important;
      }
    }
  }
}