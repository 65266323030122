.container.header {
  height: 100px;
  padding: 0px;
  margin: 0;
  box-shadow: none;
  left: auto;
  position: relative;
  z-index: 2;

  .background {
    background-color: transparent;
    padding: 0 $base-unit * 2.5;
  }

  .menu.branding {
    background-color: transparent;
    height: $base-unit * 6;
  }

  .branding {
    &.menu {
      cursor: pointer;
      a{
        box-shadow: none;
      }
      .logo{
        margin-top: 20px;
        padding: 0;
        width: $base-unit * 32;
        @include phone{
          margin-left: -$base-unit;
          width: $base-unit * 21;
        }
        img {
          margin-top: 0;
          @include phone{
            margin-left: -20px;
          }
        }
      }
    }
  }

  .main-menu {
    display: flex;
    justify-content: right;
    @include tablet{
      display: none;
    }
    > li{
      &:first-child{
        a {
          background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDU3NiA1MTIiPgogIDxkZWZzPgogICAgPHN0eWxlPgogICAgICAuY2xzLTEgewogICAgICAgIGZpbGw6ICMzMjVhMzQ7CiAgICAgICAgc3Ryb2tlLXdpZHRoOiAwcHg7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTU3NS44LDI1NS41YzAsMTgtMTUsMzIuMS0zMiwzMi4xaC0zMmwuNywxNjAuMmMwLDIuNy0uMiw1LjQtLjUsOC4xdjE2LjFjMCwyMi4xLTE3LjksNDAtNDAsNDBoLTE2Yy0xLjEsMC0yLjIsMC0zLjMtLjEtMS40LjEtMi44LjEtNC4yLjFoLTU2LjVjLTIyLjEsMC00MC0xNy45LTQwLTQwdi04OGMwLTE3LjctMTQuMy0zMi0zMi0zMmgtNjRjLTE3LjcsMC0zMiwxNC4zLTMyLDMydjg4YzAsMjIuMS0xNy45LDQwLTQwLDQwaC01NS45Yy0xLjUsMC0zLS4xLTQuNS0uMi0xLjIuMS0yLjQuMi0zLjYuMmgtMTZjLTIyLjEsMC00MC0xNy45LTQwLTQwdi0xMTJjMC0uOSwwLTEuOSwwLTIuOHYtNjkuNmgtMzIuMWMtMTgsMC0zMi0xNC0zMi0zMi4xczMtMTcsMTAtMjRMMjY2LjQsOGM3LTcsMTUtOCwyMi04czE1LDIsMjEsN2wyNTUuNCwyMjQuNWM4LDcsMTIsMTUsMTEsMjRaIi8+Cjwvc3ZnPg==");
          background-repeat: no-repeat;
          background-position: center center;
          height: 20px;
          margin-top: 0;
          text-indent: -9999px;
          width: 17px;
        }
      }
    }
    .divided {
      background-color: rgba(217,214,202,0.8);
      cursor: pointer;
      padding: 20px 15px 18px 15px;
      margin-bottom: -20px;
      a{
        display: block;
        font-weight: 600;
      }
      &:hover{
        a{
          color: $dark-text-color;
        }
      }
      &:first-child{
        @include border-radii(4px, 0, 0, 4px);
        padding-left: 25px;
      }
      &:last-child{
        @include border-radii(0, 4px, 4px, 0);
        padding-right: 25px;
      }
      &.has-child-items{
        &:hover{
          .sub-menu{
            display: block;
            margin-left: -18px;
            padding-top: 10px;
            margin-top: -22px;
            li{
              background-color: $white;
              height: 30px;
              margin: 0;
              padding: 0;
              &:hover{
                border-bottom: none;
              }
            }
            .divided{
              padding: 1% 1% 25px 1%;
            }
          }
        }
      }
      > a{
        color: $menu-link-color;
        font-size: 0.84rem;
        line-height: 135%;
        outline: none;
        span {
          @include phone {
            display: block;
            overflow: hidden;
            position: relative;
            width: $extend;
          }
        }
        &:hover,
        &:focus{
          box-shadow: none;
          color: $dark-text-color;
          outline: none;
          .menu-arrow{
            color: $menu-link-color;
          }
        }
      }
      a[href="#"]{
        &:hover,
        &:focus{
          color: $dark-text-color;
          pointer-events: none;
          text-decoration: none;
        }

      }
    }
    .sub-menu {
      background-color: $white;
      box-shadow: none;
      padding: $base-unit;
      margin-top: -6px;
      display: none;
      outline: none;
      position:absolute;
      top: $base-unit * 8;
      width: $base-unit * 25;
      z-index: 10;
      @include phone {
        margin-left: -5px;
      }
      .active{
        a{
          border-bottom: none;
          &:hover{
            border-bottom: none;
          }
        }
      }
      a{
        box-shadow: none;
        color: $submenu-link-color;
        outline:none;
        margin:0;
        padding:0;
        &:hover{
          border-bottom: none;
        }
      }
    }

  }
  .menu, .branding {
    padding: 0px;
    margin: 0px;
    margin: auto;
    .divider {
      div {
        border-left: 1px solid $light-gray;
        height: 46px;
      }
    }
    .item.fitted {
      color: #000;
      font-size: 22px;
    }
    .item {
      cursor: pointer;
      font-size: 15px;
      margin: 0px 1%;
    }

    .pages {
      padding-top: $base-unit + 1;
      width: $extend;
      .item:nth-child(1) {
        margin-left: 4%;
      }
      .item {
        a {
          color: $menu-link-color;
          padding-bottom: $base-unit;
          &:hover {
            color: $light-blue;
            border-bottom: 4px solid $theme-green;
          }
        }

        box-sizing: border-box;
        margin: 0px;
        text-transform: uppercase;
        justify-content: center;
        height: 71px;
        min-width: 12%;

        align-self: flex-end;
        border: 1px solid transparent;
      }

      .item.selected {
        font-weight: bold;
        color: $light-blue;
        background-color: #FFF;
        border-bottom: 1px solid #FFF !important;
        a {
          color: $light-blue;
        }
      }

      .item.has-child-items {
        color: $menu-link-color;
        span::after {
          transform: rotate(90deg);
          content: '\203A';
          margin-left: 10px;
          float: right;
          display: block;
        }
      }

      .item.selected.has-child-items {
        color: $light-blue;
        border: 1px solid $light-gray;
        margin-bottom: -1px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        margin-bottom: -1px;

        span::after {
          transform: rotate(-90deg);
          content: '\203A';
          margin-left: $base-unit;
          float: right;
          display: block;
        }

      }


    }
  }

  .container.child {
    .menu {
      max-width: 100%;
      margin-top: 0px;
      padding: 0px;
      border-bottom: 1px solid $light-gray;
      justify-content: center;

      .item {
        margin-top: 1px;

        a {
          color: #9798a0;
        }

        text-transform: uppercase;
        padding: 0px 10px;
        margin: 0px .5%;

        .mark {
          width: 15px;
          height: 17px;
          background-size: 15px 15px;
          margin-right: 11px;
          /* webpackIgnore: true */
          background-image: url("/child-menu-arrow.svg");
          background-repeat: no-repeat;
        }
      }

      .item.selected {

        .mark {
          width: 15px;
          height: 16px;
          background-size: 15px 15px;
          margin-right: 10px;
          /* webpackIgnore: true */
          background-image: url("/header_arrow_green.png");
          background-repeat: no-repeat;
        }
      }

      .item.selected {
        color: $theme-green;
      }

      .item.active {
        .mark {
          @extend .mark;
          /* webpackIgnore: true */
          background-image: url("/child-menu-arrow-active.svg");
        }

        a {
          font-weight: 800;
          color: $theme-green;

        }
      }
    }
  }
}

.url.breadcrumbs {
  margin-top: $base-unit * 3;
  font-size: 16px;

  span {
    color: $breadcrumb-grey;
  }

  span {
    color: $breadcrumb-grey;
  }

  span:after, a:not(:last-child):after {
    color: $breadcrumb-grey;
    font-family: $font-family;
    content: '/';
    display: inline;
    font-size: 16px;
    margin: 0px 10px;
  }

  a {
    color: $breadcrumb-grey;
  }


  * {
    padding: 0px;
    margin: 0px;
  }

  .active {
    color: $theme-green;
  }

  .entry-content {
    display: inline !important;;
  }

  .title {
    font-size: 16px;


  }

  height: 30px;
}

.menu-arrow{
  color: $menu-link-color;
  display: block;
  float: right;
  font-size: $base-unit + 5;
  margin-left: math.div($base-unit, 2);
  padding-top: 0;
}

.ui.search {
  input.prompt {
    border-radius: 0px;
  }

  i.search.icon {
    color: #FFF;
    background-color: $theme-green;
  }
}

.lang-menu{
  display: contents !important;
}

// Language selector
.lang-selector{
  @include border-radius(4px);
  background-color: rgba(255,255,255, 0.7);
  cursor: pointer;
  height: 56px;
  margin-left: 15px;
  margin-top: 12px;
  padding: 18px;
  width: 52px;
  &:hover{
    background-color: rgba(255,255,255, 0.9);
  }
  img {
    display: block;
	  height: 15px;
    margin: auto;
    opacity: 1;
    width: 25px;
    &:hover {
      opacity: 0.8;
    }
  }
  @include tablet{
    margin-left: -2px;
  }
  .options{
    @include border-radii(0, 0, 5px, 5px);
    background-color: $white;
		margin-left: -18px;
		margin-top: 10px;
		padding: 5px 10px 10px 10px;
		width: 52px;
  }
  .option{
    margin-top: 10px;
  }
  .selected{
    color: #ffffff;
    margin: 0;
    padding: 0;
    img{
      margin-left: -5px;
      margin-top: 2px;
    }
    &:hover{
      color: #ffffff;
    }
  }
  .lang-option{
    background-color: $lang-bg;
    color: $lang-text-color;
    font-size: 12px;
    height: 25px;
    padding: 8px 10px;
    &:first-child{
      @include border-radii(5px, 0, 0, 5px);
    }
    &:last-child{
      @include border-radii(0, 5px, 5px, 0);
    }
    &:hover{
      background-color: $theme-green;
      color: #ffffff;
    }
  }
}

.ui.text.menu{
  margin: 1em -0.5em !important;
}


// Hamburger nav

.hamburger {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI3LjQuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA0NDggNTEyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0NDggNTEyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6Izc4Qjg0Mzt9Cjwvc3R5bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0wLDk2YzAtMTcuNywxNC4zLTMyLDMyLTMyaDM4NGMxNy43LDAsMzIsMTQuMywzMiwzMnMtMTQuMywzMi0zMiwzMkgzMkMxNC4zLDEyOCwwLDExMy43LDAsOTZ6IE0wLDI1NgoJYzAtMTcuNywxNC4zLTMyLDMyLTMyaDM4NGMxNy43LDAsMzIsMTQuMywzMiwzMnMtMTQuMywzMi0zMiwzMkgzMkMxNC4zLDI4OCwwLDI3My43LDAsMjU2eiBNNDQ4LDQxNmMwLDE3LjctMTQuMywzMi0zMiwzMkgzMgoJYy0xNy43LDAtMzItMTQuMy0zMi0zMnMxNC4zLTMyLDMyLTMyaDM4NEM0MzMuNywzODQsNDQ4LDM5OC4zLDQ0OCw0MTZ6Ii8+Cjwvc3ZnPgo=");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: $extend - 20 $extend - 20;
  cursor: pointer;
  float: right;
  height: $base-unit * 2;
  margin-left: 5px;
  margin-top: $base-unit - 3;
  padding: 14px;
  width: $base-unit * 2;
  border: 2px solid $afo-green;
  @include desktop{
    display: none;
  }
}
.hamburger:before {
  content: "";
  display: block;
  width: $extend;
  height: 4px;
}
.hamburger:after {
  content: "";
  display: block;
  width: $extend;
  height: 4px;
  margin-top: 4px;
}

.menu.pages input[type="checkbox"]:checked ~ .main-menu {
  background-color: $white;
  border: 2px solid $lang-bg;
  display: block;
  float: none;
  margin-left: -200px;
  margin-top: 50px;
  min-height: 320px;
  position: relative;
  z-index: 999;
  overflow: hidden;
  padding: $base-unit * 2;
  width: $base-unit * 20;
  @include phone {
    margin-left: -295px;
    min-height: 400px;
    width: 295px;
  }
}

.hidden-checkbox {
  position: fixed;
  top: -$extend;
  left: -$extend;
}