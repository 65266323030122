@use "sass:math";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import 'vars';

html {
  height: 100%;
  font-size: 18px !important;
}

body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  background-color: transparent !important;
  font-size: 18px !important;
}

iframe {
  margin: 0px;
  padding: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ui {
  font-family: "Inter", sans-serif;
}

.ui.container{
  @include tablet{
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 0;
  }
}

.ui.container.narrow {
  max-width: 1080px;
}

.ui.container.fluid.desktop {
  max-width: 1366px !important;
}

.ui.container .desktop{
  @include phone{
    margin: 0 !important;
    padding: 0 10px;
    width: 100%;
  }
  >.ui.container.entry-content{
    @include tablet{
      margin-left: 0 !important;
      margin-right: 0 !important;
      padding: 0;
    }
  }
}

.ui.fluid.container{
  padding: 0;
  @include tablet{
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.ui.primary.button {
  background-color: $theme-green !important;

}

.ui.secondary.button {
  background-color: $afo-green !important;
}
.ui.menu{
  font-family: $font-family !important;
}

@import "theme/index";