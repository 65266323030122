p {
  color: $body-text-color;
  line-height: 1.5;
  font-weight: 400;
  font-size: 0.8rem;
  @include tablet {
	  text-align: left;
	}
  &.left-aligned-text{
    text-align: left;
  }
}

b, strong {
  font-weight: 700;
}


h1 {
  color: $gray;
  font-family: $title-font !important;
  font-size: 30px;
  font-weight: 400 !important;
}

h2 {
  font-family: $title-font !important;
  font-size: 1.9rem !important;
  color: $gray;
  font-weight: 400 !important;
}

h3 {
  font-family: $title-font !important;
  font-size: 1.3rem !important;
  color: $gray;
  font-weight: 400 !important;
}

h4 {
  font-family: $title-font !important;
  font-size: 1.07rem !important;
  color: $gray;
  font-weight: 400 !important;
}

h5 {
  font-family: $title-font !important;
  font-size: 1rem !important;
  color: $gray;
}

h6 {
  font-family: $title-font !important;
  font-size: .7rem !important;
  color: $gray;
}

p.title-text{
  font-family: $title-font;
}

/* Styles */

.has-standard-12-font-size {
  font-size: 12px;
}

.has-standard-14-font-size {
  font-size: 14px;
}

.has-standard-16-font-size {
  font-size: 15px;
}

.has-standard-18-font-size {
  font-size: 18px;
}

.has-standard-24-font-size {
  font-size: 24px;
}

.has-standard-30-font-size {
  font-size: 30px;
}

.has-standard-36-font-size {
  font-size: 36px;
}

.has-standard-64-font-size {
  font-size: 64px;
  line-height: 110%;
}


.has-condensed-text {
  font-stretch: condensed;
  line-height: 115% !important;
}


.has-weight-300 {
  font-weight: 300;
}

.has-weight-400 {
  font-weight: 400;
}

.has-weight-700 {
  font-weight: 700;
}