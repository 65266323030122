@import 'src/scss/vars';

.green {

  .pages {
  }

  .left.navigator {
    top: 10px;
    float: left;
    position: sticky !important;
    width: 100px;
    margin-left: -8vw;

    .ui.menu {
      background-color: $health-color;
      margin: 0;
      padding: 0;
      width: 90px;
      min-width: 70px;
      max-width: 100px;
      display: flex;

      .header {
        font-size: 1.4vmin;
        align-content: center;
        text-align: center;
        text-transform: uppercase;
      }

      .item {
        border-bottom: 1px solid #FFF;
        padding: 10% 1%;
        box-sizing: border-box;
        text-align: center;

        .label {
          padding: 3%;
          font-size: .6rem;
          margin: auto;
          background: none;
          text-transform: uppercase;
          width: 100%;
          border: 0;
        }

        img {
          height: auto;
          width: 65%;
          cursor: pointer;
          margin: -3px auto 5px auto;
        }

        color: #FFF !important;
      }

      .item.active {

        border: 1px solid $light-blue;
        background-color: $light-blue;
      }
    }
  }

  @media screen and (max-width: 1300px) {

    .left.navigator {

      .ui.menu {
        background-color: $health-color;
        margin: 0;
        padding: 0;
        width: 5vw;
        min-width: 65px;
        max-width: 100px;
        display: flex;
      }

      .label {
        display: none;
      }
    }
  }
  @media screen and (max-width: 1023px) {
    .left.navigator {


      display: none;

      .label {
        display: none;
      }
    }
  }
  @media screen and (max-width: 1023px) {
    .left.navigator {
      display: none;

      .label {
        display: none;
      }
    }
  }
}
