.wp-block-wp-react-lib-components-newsletter {
  width: 100%;
  padding: 10px;
}
.wp-react-lib.newsLetter {

  text-align: center;
  max-width: 500px;
  height: 60px;
  margin: 1rem auto;

  .ui.message {
    margin: 0px;

    p {

      padding: 0px;
      margin: 0px;
      line-height: 5px;
    }
  }

  .wp-react-lib.newsLetter.form {
    padding: 0px;
    height: 42px;
    display: flex;
    justify-content: left;
    margin-bottom: 3px;

    .input {
      flex-grow: 1;
      height: 40px;
    }

    .ui.button {
      @include border-radii(0, 5px, 5px, 0);
      font-family: $title-font;
      font-weight: 400;
      height: 40px;
      letter-spacing: 0;
      line-height: 70%;
      margin-left: 0;
    }

  }
}

.subscribe{
  .ui.icon.input>input{
    @include border-radii(5px, 0, 0, 5px);
    background-color: $input-bg;
  }
}
