.wp-react-lib.tabbed.posts.container {
  margin: 0px;
  min-height: 300px;
  padding: 0px 20px;
  @include phone{
    padding: 0;
    width: 105% !important;
   }

  .ui.container.entry-content {
    margin: 0px;
    padding: 20px 0px;
  }

  .label {
    font-weight: 400;
    margin: 0;
  }
}
.ui.grid.tabbed.posts {
  background-color: transparent !important;

  .row {
    padding: 0;
    margin: 0;
  }

  > .column {
    margin: 0;
    padding: .2vw;

    > button {
      @include border-radius(0);
      background-color: $afo-green;
      height: 60px;
      width: $extend;
      padding: 0px;
      margin: 0px;
      color: #FFF;
      text-align: left;
      &:hover{
        background-color: $afo-hover;
      }

      .label {
        color: #FFF;
        background-color: transparent !important;

      }
    }

    .one.button {
      width: auto !important;

    }
  }

  .column.has-icon {
    button {
      text-align: left;

      .icon {
        border: 0px;
        display: block;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: auto;
        float: left;
        height: 40px;

        width: auto;
        margin-left: 10px;
        margin-right: 0px;
      }


    }
  }

  button:hover {
    color: #FFF;
  }

  .active {
    button {
      color: #FFF;
      background-color: $afo-hover;
    }
  }
}


.ui.menu.tabbed.posts {
  padding: 0px;
  margin: 0px;

  .item {
    margin: 0px;
    padding: 0px;
    color: $gray !important;
    border: 0px;
    font-size: 16px;

    .label {
      background-color: transparent !important;
      color: $gray;
      padding: 0px;

    }

    &:last-child {
      &:after {
        content: none;
      }
    }
  }

  .item.active {

    .label {
      background-color: transparent !important;
      color: $afo-hover;
      font-weight: bold;
      padding: 0px;

    }

    color: $green !important;
    font-weight: 700;
    padding-left: $base-unit;

    &:after {
      color: $gray;
      font-weight: normal;
    }
  }

  a.item.active::before {
    content: '';
    //background-image: url('/green_arrow_active.svg');
    background-repeat: no-repeat;
    background-position: center;
    width: 18px !important;
    height: 20px !important;
    background-color: transparent !important;
    display: block !important;
    position: relative !important;
    margin-left: -18px;
    margin-right: $base-unit - 2;

  }

  .item::after {
    content: '|';
    margin-left: 18px;
    margin-right: 18px
  }


}


.container.wp-react-lib.tabbed.posts.editing {

  .button {
    margin-top: 20px;
  }
}
