.carousel {
  width: 100%;
  margin-bottom: -10px;
  position: relative;
  height: 100%;
  overflow: hidden;

  .wp-block-cover {
    padding: 0px;

  }

  .wp-block-group {
    min-height: 555px;
    padding: 1rem 2rem;
  }

  .wp-block-columns {
    .wp-block-column {
      padding: 0px;
      margin: 0 3px;
    }

  }

  padding: 0px;
  // applies to partner carousel with small height
  li {
    padding: 0px;
    min-height: 550px;

    .wp-block-buttons {
      padding: 10px 0px;

      .wp-block-button {
        margin: 0px;
      }
    }
  }


  .carousel__dot--selected {
  }
}

.carousel__dot-group {
  margin: 0px auto auto;
  text-align: center;
  position: relative;
  z-index: 2;

  .carousel__dot {
    pointer-events: all !important;
    background-color: $light-gray;
    margin: math.div($base-unit, 2);
    border-radius: $extend;
    height: $base-unit + 2;
    width: $base-unit + 2;
    border: 0px !important;

  }

  .carousel__dot:hover {
    background-color: #3e8e41
  }

  .carousel__dot:focus {
    outline: none;
    box-shadow: none;
  }

  .carousel__dot:active {
    background-color: #3e8e41;
    box-shadow: none;
    transform: translateY(0px);
  }

  .carousel__dot--selected {
    background-color: $afo-green;
  }

}
// Applies to carousel of posts - larger height
.post-carousel{
  .carousel{
    li{
      min-height: 550px;
      @include phone{
        min-height: 740px;
      }
    }
  }
}

// Full width slider
.wp-block-wp-react-lib-components-post-carousel{
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-top: -115px;
  z-index: -1;
}

.top-banner {
  .carousel{
    height: 600px;
    margin-bottom: -52px;
    .carousel__dot-group{
      margin-top: -60px;
    }
  }
}

// Partner carousel
.wp-block-wp-react-lib-components-post-carousel{
  &.partner-carousel{
    margin: 0 auto;
    min-height: auto;
    width: $extend;
    z-index: 1;
    .carousel__dot-group {
      top: -20px;
    }
    .carousel{
      li{
        min-height: 200px;
        @include tablet{
          min-height: 150px;
        }
      }
    }
  }
}