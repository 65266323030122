
.has-white-color {
  color: #FFF
}

.has-white-background-color{
  background-color: #ffffff;
}
.has-light-blue-color {
  color: $light-blue
}


.has-light-blue-background-color {
  background-color: $light-blue !important;
}

.has-blue-color {
  color: $blue
}

.has-blue-background-color {
  background-color: $blue;
}


.has-gray-color {
  color: $gray
}

.has-gray-border {
  border: 1px solid #EEE;
}

.has-gray-background-color {
  background-color: $gray;
}

.has-dark-gray-color {
  color: $dark-gray;
}

.has-dark-gray-background-color {
  color: $dark-gray
}

.has-green-color {
  color: $green
}

.has-green-background-color {
  background-color: $green;
}

.has-red-color {
  color: $red
}

.has-red-background-color {
  background-color: $red;
}

.has-ligth-green-color {
  color: $light-green
}

.has-ligth-green-background-color {
  background-color: $light-green;
}
