.container.wp-react-lib.footer {
  background-color: $footer-bg;
  line-height: 16px !important;

  .bottom-bar {
    background-color: transparent;
    color: #ffffff;
    margin-bottom: 0;
    padding: 0;
    .wp-block-columns{
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .column {
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
  }

  .header {
    font-weight: 400;
    color: white;
    text-transform: uppercase;
    margin: 0px;
  }

  p {
    color: #ffffff;
    margin: 0 0 0.3rem 0;
    a {
      color: #ffffff;
      &:hover {
        color: $afo-green;
      }
    }
  }

  .wp-react-lib.secondary-menu.container {
    background-color: #175372;
    text-align: center;
  }
  .wp-block-separator {
    border: 1px solid $footer-separator;
  }
}
